import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
// import moment from 'moment'
import API from '@/services/api'

export const printPreDesignPDF = async (preDesign, cost) => {
    try {
        const {
            data: { settings },
        } = await API.getSettings()
        let company = settings.find(s => s.name == 'Company').generalInfo
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [250, 300],
        })
        const structuralElements0 = [
            {
                type: 'PANELS',
                fields: ['DIMENSIONS', 'THK', 'DEFLECTION'],
            },
            {
                type: 'MULLIONS',
                fields: ['DIMENSIONS', 'MAX SPAN', 'DEFLECTION'],
            },
        ]
        const structuralElements = [
            { type: 'BRACKET', fields: ['DIMENSIONS', 'THK'] },
            { type: 'FASTENERS', fields: ['SPECIFICATION', 'QTY'] },
            { type: 'ANCHORS', fields: ['SPECIFICATION', 'EMBEDEMENT'] },
        ]
        const structuralElements1 = [
            {
                type: 'OTHERS',
                fields: [
                    'DIMENSIONS',
                    'DEFLECTION',
                    'MAX SPAN',
                    'SPECIFICATION',
                    'THK',
                    'QTY',
                    'EMBEDEMENT',
                ],
            },
        ]
        //Set sections by elements type
        const elementsByType = preDesign.elements.reduce((acc, current) => {
            if (!acc[current.type]) {
                acc[current.type] = []
            }
            acc[current.type].push(current)

            return acc
        }, {})
        // set header and footer
        let aspectRatio = await getAspectRatio(company.logoBase64)
        const header = function(data) {
            const imgHeight = 15
            const imgWidth = imgHeight * aspectRatio
            const imgX = data.settings.margin.left + 15
            const imgY = 15
            pdf.addImage(
                company.logoBase64,
                'PNG',
                imgX,
                imgY,
                imgWidth,
                imgHeight
            )
        }
        // desc options
        let descriptionOptions = {
            styles: {
                halign: 'center',
                valign: 'middle',
                cellPadding: 0,
                margin: 0,
                fontSize: 8,
                lineColor: [0, 0, 0],
                lineWidth: 0.5,
                minCellHeight: 6,
            },
            tableLineColor: [0, 0, 0],
            margin: { top: 35, left: 10 },
            headStyles: {
                fillColor: [211, 211, 211],
                textColor: [0, 0, 0],
            },
        }

        const fontSize = 5
        pdf.setFontSize(fontSize)
        const totalWidth = pdf.internal.pageSize.width - 20

        const tableDataInfo = [
            [
                { content: '', rowSpan: 3, styles: { minCellHeight: 25 } },
                {
                    content: 'PREDESIGN FORMAT',
                    rowSpan: 3,
                    styles: {
                        fontStyle: 'bold',
                        fontSize: 12,
                    },
                },
                {
                    content: 'CODE',
                    rowSpan: 1,
                    styles: {
                        fontStyle: 'bold',
                    },
                },
                { content: preDesign.code ? preDesign.code : '', rowSpan: 1 },
            ],
            [
                {
                    content: 'DATE',
                    rowSpan: 1,
                    styles: {
                        fontStyle: 'bold',
                    },
                },
                { content: preDesign.date, rowSpan: 1 },
            ],
            [
                {
                    content: 'VERSION',
                    rowSpan: 1,
                    styles: {
                        fontStyle: 'bold',
                    },
                },
                {
                    content: preDesign.versionNumber
                        ? preDesign.versionNumber
                        : '',
                    rowSpan: 1,
                },
            ],
            [
                {
                    content: '',
                    colSpan: 4,
                    styles: { fillColor: [255, 255, 255] },
                }, // Fila vacía para separación
            ],
        ]
        pdf.autoTable({
            body: tableDataInfo,
            startY: 10, // Posición inicial
            theme: 'grid',
            ...descriptionOptions,
            didDrawPage: function(data) {
                header(data)
            },
            columnStyles: {
                0: { cellWidth: totalWidth * 0.3 },
                1: { cellWidth: totalWidth * 0.4 },
                2: { cellWidth: totalWidth * 0.15 },
                3: { cellWidth: totalWidth * 0.15 },
            },
        })
        descriptionOptions.startY = pdf.autoTable.previous.finalY
        descriptionOptions.margin = { top: 0, left: 10 }
        const tableDataInfo2 = [
            [
                {
                    content: 'ENGINEER: ',
                    colSpan: 1,
                    styles: {
                        fillColor: [217, 217, 217],
                        halign: 'left',
                        cellPadding: { left: 2, top: 0, right: 0, bottom: 0 },
                        fontStyle: 'bold',
                    },
                },
                { content: preDesign.engineer, colSpan: 1 },
                {
                    content: 'PROJECT: ',
                    colSpan: 1,
                    styles: {
                        fillColor: [217, 217, 217],
                        halign: 'left',
                        cellPadding: { left: 2, top: 0, right: 0, bottom: 0 },
                        fontStyle: 'bold',
                    },
                },
                { content: cost.quote ? cost.quote.name : '', colSpan: 1 },
            ],
            [
                {
                    content: 'ESTIMATOR: ',
                    colSpan: 1,
                    styles: {
                        fillColor: [217, 217, 217],
                        halign: 'left',
                        cellPadding: { left: 2, top: 0, right: 0, bottom: 0 },
                        fontStyle: 'bold',
                    },
                },
                {
                    content: preDesign.estimator,
                    colSpan: 3,
                },
            ],
        ]

        pdf.autoTable({
            body: tableDataInfo2,
            theme: 'grid',
            tableLineColor: [0, 0, 0],

            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.3 },
                1: { cellWidth: totalWidth * 0.2 },
                2: { cellWidth: totalWidth * 0.2 },
                3: { cellWidth: totalWidth * 0.3 },
            },
        })

        descriptionOptions.startY = pdf.autoTable.previous.finalY
        descriptionOptions.styles.minCellHeight = 5
        const tableDataInfo3 = [
            [
                {
                    content: 'DATE: ',
                    colSpan: 1,
                    styles: {
                        fillColor: [217, 217, 217],
                        halign: 'left',
                        cellPadding: { left: 2, top: 0, right: 0, bottom: 0 },
                        fontStyle: 'bold',
                    },
                },
                {
                    content: preDesign.engineeringDate,
                    colSpan: 1,
                },
                {
                    content: 'LOCATION (CITY/STATE) ',
                    colSpan: 1,
                    styles: {
                        fillColor: [217, 217, 217],
                        halign: 'left',
                        cellPadding: { left: 2, top: 0, right: 0, bottom: 0 },
                        fontStyle: 'bold',
                    },
                }, // Unifica 3 filas
                { content: preDesign.location, colSpan: 1 },
            ],
            [
                {
                    content: 'STRUCTURAL SET',
                    colSpan: 3,
                    styles: {
                        fillColor: [217, 217, 217],
                        cellPadding: { left: 0, top: 0, right: 2, bottom: 0 },
                        halign: 'right',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: preDesign.structuralSet
                        ? preDesign.structuralSet
                        : '',
                    colSpan: 1,
                    styles: { textColor: [255, 0, 0], fontStyle: 'bold' },
                },
            ],
            [
                {
                    content: 'WIND TUNNEL RWDI REPORT',
                    colSpan: 3,
                    styles: {
                        fillColor: [217, 217, 217],
                        cellPadding: { left: 0, top: 0, right: 2, bottom: 0 },
                        halign: 'right',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: preDesign.windTunnelReport
                        ? preDesign.windTunnelReport
                        : '',
                    colSpan: 1,
                    styles: { textColor: [255, 0, 0], fontStyle: 'bold' },
                },
            ],
            [
                {
                    content: 'WIND CODE STANDARDS',
                    colSpan: 3,
                    styles: {
                        fillColor: [217, 217, 217],
                        cellPadding: { left: 0, top: 0, right: 2, bottom: 0 },
                        halign: 'right',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: preDesign.windCodeStd ? preDesign.windCodeStd : '',
                    colSpan: 1,
                    styles: { textColor: [255, 0, 0], fontStyle: 'bold' },
                },
            ],
        ]

        pdf.autoTable({
            body: tableDataInfo3,
            theme: 'grid',
            tableLineColor: [0, 0, 0],

            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.1 },
                1: { cellWidth: totalWidth * 0.2 },
                2: { cellWidth: totalWidth * 0.2 },
                3: { cellWidth: totalWidth * 0.5 },
            },
        })

        descriptionOptions.startY = pdf.autoTable.previous.finalY
        let tableDataInfo4 = [
            [
                {
                    content: 'WIND CRITERIA',
                    colSpan: 1,
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: 'unit',
                    colSpan: 1,
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: 'Value',
                    colSpan: 1,
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: 'Comments',
                    colSpan: 1,
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
            ],
        ]
        preDesign.criteriaFields.forEach(criterion =>
            tableDataInfo4.push([
                {
                    content: criterion.criteria,
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        cellPadding: { left: 2, top: 0, right: 0, bottom: 0 },
                    },
                },
                {
                    content: criterion.unit
                        ? criterion.unit
                        : criterion.value
                        ? 'category'
                        : '',
                    colSpan: 1,
                },
                { content: criterion.value ? criterion.value : '', colSpan: 1 },
                {
                    content: criterion.comments ? criterion.comments : '',
                    colSpan: 1,
                },
            ])
        )
        tableDataInfo4.push([
            {
                content: '',
                colSpan: 4,
                styles: { fillColor: [255, 255, 255] },
            }, // Empty row separation
        ])
        pdf.autoTable({
            body: tableDataInfo4,
            theme: 'grid',
            tableLineColor: [0, 0, 0],

            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.3 },
                1: { cellWidth: totalWidth * 0.1 },
                2: { cellWidth: totalWidth * 0.1 },
                3: { cellWidth: totalWidth * 0.5 },
            },
        })

        descriptionOptions.startY = pdf.autoTable.previous.finalY

        let tableDataInfo5_0 = [
            [
                {
                    content: 'STRUCTURAL ELEMENTS',
                    colSpan: 5,
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                }, // Empty row
            ],
        ]

        structuralElements0.forEach(element => {
            tableDataInfo5_0.push([
                {
                    content: element.type,
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements0.find(
                        e => e.type == element.type
                    ).fields[0],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements0.find(
                        e => e.type == element.type
                    ).fields[1],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements0.find(
                        e => e.type == element.type
                    ).fields[2],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: 'Comments',
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
            ])
            if (elementsByType[element.type]) {
                elementsByType[element.type].forEach(item => {
                    tableDataInfo5_0.push([
                        {
                            content: item.items.name,
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                cellPadding: {
                                    left: 2,
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                },
                            },
                        },
                        {
                            content: item.items.dimensions
                                ? item.items.dimensions
                                : item.items.specification
                                ? item.items.specification
                                : '',
                            colSpan: 1,
                            styles: {
                                cellPadding: {
                                    left: 2,
                                    top: 0.5,
                                    right: 2,
                                    bottom: 0.5,
                                },
                            },
                        },
                        {
                            content:
                                item.items.max_span_left ||
                                item.items.max_span_rigth
                                    ? `${
                                          item.items.max_span_left
                                              ? `${item.items.max_span_left}${
                                                    item.items.detailUnit ==
                                                    'Metric'
                                                        ? 'cm'
                                                        : "'"
                                                }`
                                              : ''
                                      } ${
                                          item.items.max_span_rigth
                                              ? `${item.items.max_span_rigth}${
                                                    item.items.detailUnit ==
                                                    'Metric'
                                                        ? 'mm'
                                                        : '"'
                                                }`
                                              : ''
                                      }`
                                    : item.items.THK
                                    ? `${item.items.THK}${
                                          item.items.thkUnit == 'in'
                                              ? '"'
                                              : 'mm'
                                      }`
                                    : item.items.qty
                                    ? `${item.items.qty}${item.items.unit}`
                                    : '',
                            colSpan: 1,
                        },
                        {
                            content: item.items.deflection
                                ? `L/${item.items.deflection}`
                                : '',
                            colSpan: 1,
                        },
                        {
                            content: item.items.comments
                                ? item.items.comments
                                : '',
                            colSpan: 1,
                        },
                    ])
                })
            }
            tableDataInfo5_0.push([
                {
                    content: '',
                    colSpan: 5,
                    styles: { fillColor: [255, 255, 255] },
                }, // Fila vacía para separación
            ])
        })
        pdf.autoTable({
            body: tableDataInfo5_0,
            theme: 'grid', // Centrar texto, negro intenso en todas las líneas
            tableLineColor: [0, 0, 0], // Cambiar color de las líneas separadoras a negro intenso
            // tableLineWidth: 0.5, // Aumentar el grosor de las líneas separadoras

            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.15 },
                1: { cellWidth: totalWidth * 0.2 },
                2: { cellWidth: totalWidth * 0.15 },
                3: { cellWidth: totalWidth * 0.15 },
                4: { cellWidth: totalWidth * 0.35 },
            },
        })

        descriptionOptions.startY = pdf.autoTable.previous.finalY

        let tableDataInfo5 = []
        structuralElements.forEach(element => {
            tableDataInfo5.push([
                {
                    content: element.type,
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements.find(
                        e => e.type == element.type
                    ).fields[0],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements.find(
                        e => e.type == element.type
                    ).fields[1],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: 'Comments',
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
            ])
            if (elementsByType[element.type]) {
                elementsByType[element.type].forEach(item => {
                    tableDataInfo5.push([
                        {
                            content: item.items.name,
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                cellPadding: {
                                    left: 2,
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                },
                            },
                        },
                        {
                            content: item.items.dimensions
                                ? item.items.dimensions
                                : item.items.specification
                                ? item.items.specification
                                : '',
                            colSpan: 1,
                            styles: {
                                cellPadding: {
                                    left: 2,
                                    top: 0.5,
                                    right: 2,
                                    bottom: 0.5,
                                },
                            },
                        },
                        {
                            content: item.items.THK
                                ? `${item.items.THK}${
                                      item.items.unit == 'in' ? '"' : 'cm'
                                  }`
                                : item.items.max_span
                                ? `${item.items.max_span}${
                                      item.items.unit == 'in' ? '"' : 'cm'
                                  }`
                                : item.items.qty
                                ? item.items.qty
                                : item.items.embedment_left ||
                                  item.items.embedment_rigth
                                ? `${
                                      item.items.embedment_left
                                          ? `${item.items.embedment_left}${
                                                item.items.detailUnit ==
                                                'Metric'
                                                    ? 'cm'
                                                    : "'"
                                            }`
                                          : ''
                                  } ${
                                      item.items.embedment_rigth
                                          ? `${item.items.embedment_rigth}${
                                                item.items.detailUnit ==
                                                'Metric'
                                                    ? 'mm'
                                                    : '"'
                                            }`
                                          : ''
                                  }`
                                : '',
                            colSpan: 1,
                        },
                        {
                            content: item.items.comments
                                ? item.items.comments
                                : '',
                            colSpan: 1,
                        },
                    ])
                })
            }

            tableDataInfo5.push([
                {
                    content: '',
                    colSpan: 4,
                    styles: { fillColor: [255, 255, 255] },
                },
            ])
            pdf.autoTable({
                body: tableDataInfo5,
                startY: pdf.autoTable.previous.finalY,
                theme: 'grid',
                tableLineColor: [0, 0, 0],

                ...descriptionOptions,
                columnStyles: {
                    0: { cellWidth: totalWidth * 0.15 },
                    1: { cellWidth: totalWidth * 0.2 },
                    2: { cellWidth: totalWidth * 0.15 },
                    3: { cellWidth: totalWidth * 0.5 },
                },
            })
            tableDataInfo5 = []
            descriptionOptions.startY = pdf.autoTable.previous.finalY
        })
        pdf.autoTable({
            body: tableDataInfo5,
            theme: 'grid',
            tableLineColor: [0, 0, 0],

            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.4 },
                1: { cellWidth: totalWidth * 0.4 },
                2: { cellWidth: totalWidth * 0.1 },
                3: { cellWidth: totalWidth * 0.1 },
            },

            didDrawPage: data => {
                const yPos = data.cursor.y + 10

                const notes = 'Notes: '
                const notas = `${preDesign.notes ? preDesign.notes : ''}`
                pdf.setFontSize(10)
                pdf.setFont('helvetica', 'bold')
                pdf.text(notes, 14, yPos)
                const notesWidth = pdf.getTextWidth(notes)
                pdf.setFont('helvetica', 'normal')
                pdf.text(notas, 14 + notesWidth, yPos)
            },
        })

        descriptionOptions.startY = pdf.autoTable.previous.finalY

        let tableDataInfo6 = []
        structuralElements1.forEach(element => {
            tableDataInfo6.push([
                {
                    content: element.type,
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements1.find(
                        e => e.type == element.type
                    ).fields[0],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements1.find(
                        e => e.type == element.type
                    ).fields[1],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements1.find(
                        e => e.type == element.type
                    ).fields[2],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements1.find(
                        e => e.type == element.type
                    ).fields[3],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements1.find(
                        e => e.type == element.type
                    ).fields[4],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements1.find(
                        e => e.type == element.type
                    ).fields[5],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: structuralElements1.find(
                        e => e.type == element.type
                    ).fields[6],
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
                {
                    content: 'Comments',
                    styles: {
                        fillColor: [217, 217, 217],
                        fontStyle: 'bold',
                    },
                },
            ])
            if (elementsByType[element.type]) {
                elementsByType[element.type].forEach(item => {
                    tableDataInfo6.push([
                        {
                            content: item.items.name,
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                cellPadding: {
                                    left: 2,
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                },
                            },
                        },
                        {
                            content: item.items.dimensions
                                ? item.items.dimensions
                                : '',
                            colSpan: 1,
                            styles: {
                                cellPadding: {
                                    left: 2,
                                    top: 0.5,
                                    right: 2,
                                    bottom: 0.5,
                                },
                            },
                        },
                        {
                            content: item.items.deflection
                                ? `${item.items.deflection}${
                                      item.items.unit == 'in' ? '"' : 'cm'
                                  }`
                                : '',
                            colSpan: 1,
                            styles: {
                                cellPadding: {
                                    left: 2,
                                    top: 0.5,
                                    right: 2,
                                    bottom: 0.5,
                                },
                            },
                        },
                        {
                            content: item.items.max_span
                                ? item.items.max_span
                                : '',
                            colSpan: 1,
                            styles: {
                                cellPadding: {
                                    left: 2,
                                    top: 0.5,
                                    right: 2,
                                    bottom: 0.5,
                                },
                            },
                        },
                        {
                            content: item.items.specification
                                ? item.items.specification
                                : '',
                            colSpan: 1,
                            styles: {
                                cellPadding: {
                                    left: 2,
                                    top: 0.5,
                                    right: 2,
                                    bottom: 0.5,
                                },
                            },
                        },
                        {
                            content: item.items.THK
                                ? `${item.items.THK}${
                                      item.items.thkUnit == 'in' ? '"' : 'mm'
                                  }`
                                : '',
                            colSpan: 1,
                            styles: {
                                cellPadding: {
                                    left: 2,
                                    top: 0.5,
                                    right: 2,
                                    bottom: 0.5,
                                },
                            },
                        },
                        {
                            content: item.items.qty ? item.items.qty : '',
                            colSpan: 1,
                            styles: {
                                cellPadding: {
                                    left: 2,
                                    top: 0.5,
                                    right: 2,
                                    bottom: 0.5,
                                },
                            },
                        },
                        {
                            content:
                                item.items.embedment_left ||
                                item.items.embedment_rigth
                                    ? `${
                                          item.items.embedment_left
                                              ? `${item.items.embedment_left}${
                                                    item.items.detailUnit ==
                                                    'Metric'
                                                        ? 'cm'
                                                        : "'"
                                                }`
                                              : ''
                                      } ${
                                          item.items.embedment_rigth
                                              ? `${item.items.embedment_rigth}${
                                                    item.items.detailUnit ==
                                                    'Metric'
                                                        ? 'mm'
                                                        : '"'
                                                }`
                                              : ''
                                      }`
                                    : '',
                            colSpan: 1,
                            styles: {
                                cellPadding: {
                                    left: 2,
                                    top: 0.5,
                                    right: 2,
                                    bottom: 0.5,
                                },
                            },
                        },

                        {
                            content: item.items.comments
                                ? item.items.comments
                                : '',
                            colSpan: 1,
                        },
                    ])
                })
            }

            tableDataInfo6.push([
                {
                    content: '',
                    colSpan: 9,
                    styles: { fillColor: [255, 255, 255] },
                },
            ])
            pdf.autoTable({
                body: tableDataInfo6,
                startY: pdf.autoTable.previous.finalY,
                theme: 'grid',
                tableLineColor: [0, 0, 0],

                ...descriptionOptions,
                columnStyles: {
                    0: { cellWidth: totalWidth * 0.1 },
                    1: { cellWidth: totalWidth * 0.1 },
                    2: { cellWidth: totalWidth * 0.1 },
                    3: { cellWidth: totalWidth * 0.08 },
                    4: { cellWidth: totalWidth * 0.12 },
                    5: { cellWidth: totalWidth * 0.09 },
                    6: { cellWidth: totalWidth * 0.09 },
                    7: { cellWidth: totalWidth * 0.11 },
                    8: { cellWidth: totalWidth * 0.21 },
                },
            })
            tableDataInfo6 = []
            descriptionOptions.startY = pdf.autoTable.previous.finalY
        })

        tableDataInfo6.push([
            {
                content: 'STRUCTURAL ASSUMPTIONS / SITE SPECIAL CONDITIONS',
                colSpan: 9,
                styles: {
                    fillColor: [217, 217, 217],
                    fontStyle: 'bold',
                },
            },
        ])
        // Crear la tabla

        pdf.autoTable({
            body: tableDataInfo6,
            theme: 'grid',
            tableLineColor: [0, 0, 0],

            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.4 },
                1: { cellWidth: totalWidth * 0.4 },
                2: { cellWidth: totalWidth * 0.1 },
                3: { cellWidth: totalWidth * 0.1 },
            },

            didDrawPage: data => {
                const yPos = data.cursor.y + 10

                const notes = 'Notes: '
                const notas = `${preDesign.notes ? preDesign.notes : ''}`
                pdf.setFontSize(10)
                pdf.setFont('helvetica', 'bold')
                pdf.text(notes, 14, yPos)
                const notesWidth = pdf.getTextWidth(notes)
                pdf.setFont('helvetica', 'normal')
                pdf.text(notas, 14 + notesWidth, yPos)
            },
        })
        // descriptionOptions.startY = pdf.autoTable.previous.finalY + 10

        const pageHeight = pdf.internal.pageSize.height - 20
        const pageWidth = pdf.internal.pageSize.width - 20
        const spacing = 10
        let currentX = 10
        let currentY = pdf.autoTable.previous.finalY + 10
        let prevHeight = 0
        currentY += spacing

        if (preDesign.images) {
            let scaleFactor = 1
            const resizedImages = []
            const largestImage = preDesign.images.reduce(
                (max, img) => {
                    const area = img.width * img.height
                    return area > max.area ? { ...img, area } : max
                },
                { area: 0 }
            )
            scaleFactor = Math.min(
                pageWidth / largestImage.width,
                pageHeight / largestImage.height
            )
            preDesign.images.forEach(image => {
                let newWidth = image.width
                let newHeight = image.height
                if (newWidth > pageWidth || newHeight > pageHeight) {
                    aspectRatio = image.width / image.height
                    newWidth = pageWidth
                    newHeight = pageWidth / aspectRatio
                    if (newHeight > pageHeight) {
                        newHeight = pageHeight
                        newWidth = pageHeight * aspectRatio
                    }
                }
                if (
                    image.width < pageWidth * 1.5 ||
                    image.height < pageHeight * 1.5
                ) {
                    newWidth = image.width * scaleFactor
                    newHeight = image.height * scaleFactor
                    if (
                        newWidth < pageWidth * 0.35 ||
                        newHeight < pageHeight * 0.35
                    ) {
                        aspectRatio = image.width / image.height
                        newWidth = pageWidth * 0.35
                        newHeight = (pageWidth * 0.35) / aspectRatio
                        if (newHeight > pageHeight * 0.35) {
                            newHeight = pageHeight * 0.35
                            newWidth = pageHeight * 0.35 * aspectRatio
                        }
                    }
                }
                resizedImages.push({
                    src: image.src,
                    width: newWidth,
                    height: newHeight,
                })
            })
            resizedImages.forEach(img => {
                if (currentX + img.width <= pageWidth) {
                    if (currentX != 10) {
                        currentY += -(prevHeight + spacing)
                    }
                    if (currentY + img.height > pageHeight) {
                        pdf.addPage()
                        currentY = 10
                        currentX = 10
                    }
                    pdf.addImage(
                        img.src,
                        'JPEG',
                        currentX,
                        currentY,
                        img.width,
                        img.height
                    )
                    currentX += img.width
                    currentY += img.height
                } else {
                    currentX = 10
                    if (currentY + img.height > pageHeight) {
                        pdf.addPage()
                        currentY = 10
                    }
                    pdf.addImage(
                        img.src,
                        'JPEG',
                        currentX,
                        currentY,
                        img.width,
                        img.height
                    )
                    currentX += img.width
                    currentY += img.height
                }
                prevHeight = img.height
                currentY += spacing
                currentX += spacing
            })
        }

        const pdfDataUri = pdf.output('datauristring')
        const blob = dataURItoBlob(pdfDataUri)
        const blobURL = URL.createObjectURL(blob)
        const printWindow = window.open(blobURL)
        printWindow.onload = function() {
            URL.revokeObjectURL(blobURL)
        }
        return blob
    } catch (error) {
        console.log(error)
    }
}

const getAspectRatio = async file => {
    return new Promise(function(resolved) {
        var i = new Image()
        i.onload = function() {
            resolved(i.width / i.height)
        }
        i.src = file
    })
}

const dataURItoBlob = dataURI => {
    const byteString = Uint8Array.from(atob(dataURI.split(',')[1]), c =>
        c.charCodeAt(0)
    )
    const blobType = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]
    return new Blob([byteString], { type: blobType })
}
